import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Box, Paper } from '@material-ui/core';
import './i18n';

import theme from './styles/theme';
import Navbar from './components/Navbar';

import Home from './pages/home';
import About from './pages/about';
import Resources from './pages/resources';
import Initiatives from './pages/initiatives';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <Navbar />
      <Box maxWidth={1000} margin="auto" px={2}>
        <Paper elevation={1}>
          <Box p={2} m={2}>
            <Switch>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/initiatives">
                <Initiatives />
              </Route>
              <Route path="/resources">
                <Resources />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </Box>
        </Paper>
      </Box>
    </Router>
  </ThemeProvider>,
  document.querySelector('#root'),
);
