import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useQuery } from 'react-query';
import axios from 'axios';

import InfoCard from './InfoCard';

const getAggregateValues = async () => {
  const { data } = await axios.get('/.netlify/functions/getAggregateData');
  return data;
};

const AggregateOverview = () => {
  const { status, data, error, isFetching } = useQuery(
    'aggregateValues',
    getAggregateValues,
  );

  return (
    <>
      <Typography variant="h5">Gesamtwerte</Typography>
      <Box mt={2}>
        <Grid container spacing={4}>
          {status === 'loading' ? (
            <>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <Skeleton variant="rect" height={180} />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <Skeleton variant="rect" height={180} />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <Skeleton variant="rect" height={180} />
              </Grid>
            </>
          ) : (
            data.aggregateValues
              .filter((v) => !isNaN(v.total))
              .map((data, i) => (
                <Grid key={i} item lg={4} md={4} sm={6} xs={12}>
                  <InfoCard data={data} />
                </Grid>
              ))
          )}
        </Grid>
      </Box>
    </>
  );
};

export default AggregateOverview;
