import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Link,
  AppBar,
  Toolbar,
  Typography,
  Box,
  useMediaQuery,
  IconButton,
  SwipeableDrawer,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  Menu,
  TimelineTwoTone,
  ListTwoTone,
  InfoTwoTone,
  GroupTwoTone,
} from '@material-ui/icons';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  margin-right: 18px;
  color: ${(props) => props.white && 'white'};
`;

const Navbar = () => {
  const matches = useMediaQuery('(max-width:800px)');
  const [isOpen, toggleOpen] = useState(false);
  return (
    <AppBar position="static">
      <Toolbar>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box display="flex" alignItems="center">
            <StyledLink white component={RouterLink} underline="none" to="/">
              <Typography variant="h6">co.vid19.at</Typography>
            </StyledLink>
          </Box>
          {matches ? (
            <IconButton
              onClick={() => toggleOpen(true)}
              style={{ color: 'white' }}
            >
              <Menu fontSize="large" />
            </IconButton>
          ) : (
            <Box display="flex" flex>
              <StyledLink
                white
                component={RouterLink}
                underline="none"
                to="/resources"
              >
                <Typography variant="h6">Ressourcen</Typography>
              </StyledLink>
              <StyledLink
                white
                component={RouterLink}
                underline="none"
                to="/initiatives"
              >
                <Typography variant="h6">Initiativen</Typography>
              </StyledLink>
              <StyledLink
                white
                component={RouterLink}
                underline="none"
                to="/about"
              >
                <Typography variant="h6">Über das Projekt</Typography>
              </StyledLink>
            </Box>
          )}
        </Box>
      </Toolbar>
      <React.Fragment>
        <SwipeableDrawer
          open={isOpen}
          onClose={() => toggleOpen(!isOpen)}
          onOpen={() => toggleOpen(!isOpen)}
        >
          <Box width={240}>
            <List>
              <StyledLink component={RouterLink} underline="none" to="/">
                <ListItem button onClick={() => toggleOpen(false)}>
                  <ListItemIcon>
                    <TimelineTwoTone />
                  </ListItemIcon>
                  <Typography variant="h6">Graphs</Typography>
                </ListItem>
              </StyledLink>
              <StyledLink
                component={RouterLink}
                underline="none"
                to="/resources"
              >
                <ListItem button onClick={() => toggleOpen(false)}>
                  <ListItemIcon>
                    <ListTwoTone />
                  </ListItemIcon>
                  <Typography variant="h6">Ressourcen</Typography>
                </ListItem>
              </StyledLink>
              <StyledLink
                component={RouterLink}
                underline="none"
                to="/initiatives"
              >
                <ListItem button onClick={() => toggleOpen(false)}>
                  <ListItemIcon>
                    <GroupTwoTone />
                  </ListItemIcon>
                  <Typography variant="h6">Initiativen</Typography>
                </ListItem>
              </StyledLink>
              <StyledLink component={RouterLink} underline="none" to="/about">
                <ListItem button onClick={() => toggleOpen(false)}>
                  <ListItemIcon>
                    <InfoTwoTone />
                  </ListItemIcon>
                  <Typography variant="h6">Über uns</Typography>
                </ListItem>
              </StyledLink>
            </List>
          </Box>
        </SwipeableDrawer>
      </React.Fragment>
    </AppBar>
  );
};

export default Navbar;
