import React from 'react';
import { Typography, Box } from '@material-ui/core';
import coffeeImage from '../../images/coffee.jpg';
import styled from 'styled-components';

const StyledImage = styled.img`
  width: 100%;
  max-width: 400px;
  border-radius: 4px;
  margin: auto;
`;

const Initiatives = () => {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        Initiativen
      </Typography>
      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
        Wie Sie heimische Unternehmen in Zeiten der Corona-Krise unterstützen
        können.{' '}
      </Typography>
      <Box display="flex" justifyContent="center" mt={2}>
        <StyledImage src={coffeeImage} alt="coffee shop" />
      </Box>
      <Typography align="justify">
        <p>
          Viele Klein- und Mittelbetriebe in Österreich stehen zurzeit vor einer
          großen Herausforderung. Aufgrund der Corona-Krise wurden seit 16. März
          alle nicht lebensnotwendigen Betriebe vorübergehend geschlossen.
          Handelsgeschäfte, Gastronomiebetriebe, Kinos, Fitnesscenter sowie
          Friseursalons sind am schlimmsten davon betroffen und müssen mit
          massiven Umsatzverlust rechnen.
        </p>
        <p>
          Um Unternehmen aus der Notlage zu helfen und Existenzen zu sichern
          wurden bereits einige Initiativen ins Leben gerufen. Wir stellen Euch
          diese vor und geben ein paar Tipps wir Ihr Klein- und Mittelbetriebe
          in dieser Zeit unter die Arme greifen könnt. Kennst Du noch weitere
          Initiativen? Dann kontaktiere uns damit wir diese in unsere Liste
          aufnehmen können.
        </p>
        <p>
          <b>Handel: </b>
        </p>
        <p>
          Die meisten Österreicher haben schon mal online bestellt. Um gezielt
          Österreichische Händler zu unterstützen und nicht immer die typischen
          Big Player im Online-Handel, hat die österreichische Bundesregierung
          eine Initiative gestartet und einen Online-Marktplatz für heimische
          Produkte ins Leben gerufen. Unter{' '}
          <a href="www.oesterreich.gv.at/public/onlinemarktplatz">
            www.oesterreich.gv.at/public/onlinemarktplatz
          </a>{' '}
          kannst du digital und lokal einkaufen. Für alle regionalen Händler die
          noch nicht digital unterwegs sind, gibt es einen schnellen Weg zum
          eigenen E-Shop. Digitalisierung lohnt sind!
        </p>
        <p>
          Auf <a href="www.goodity.at">www.goodity.at</a> finden sie einen
          regionalen Marktplatz für kleine österreichische Unternehmen, welche
          unter der derzeitigen Situation leiden.
        </p>
        <p>
          Die Suchmaschine für heimische Online-Händler{' '}
          <a href="www.anna-kauft.at">www.anna-kauft.at</a> bietet eine weitere
          Möglichkeit heimische, digitalisierte Unternehmen zu finden und in den
          Online-Shops vorbeizuschauen. Lerne regionale Betriebe kennen, von
          denen du eventuell noch gar nicht wusstest.
        </p>
        <p>
          <b> Gastronomie:</b>
        </p>
        <p>
          Du kannst weiterhin deine Lieblingslokale unterstützen indem du Essen
          online bestellst. Auf den Lieferseiten{' '}
          <a href="www.lieferando.at">www.lieferando.at</a> sowie{' '}
          <a href="www.mjam.net">www.mjam.net</a> kannst du dank kontaktloser
          Lieferung weiterhin Kulinarik aus aller Welt schnell und einfach
          genießen. Nach einem langen Tag im Home Office eine verdiente
          Belohnung.
        </p>
        <p>
          Pronto Volante heißt das Notfall-Zustell-Programm der beliebten
          Pizzeria Disco Volante aus dem 6. Bezirk. Das Projekt wurde pronto ins
          Leben gerufen, um mittags und abends hungrige Pizzafans zu versorgen.
          Kontaktlose Zustellung im 2km Radius um das Lokal sowie kontaktlose
          Abholung sind hier möglich. Unter{' '}
          <a href="www.pronto-volante.at">www.pronto-volante.at</a> Liefergebiet
          und Speisekarte einsehen und gleich bestellen.
        </p>
        <p>
          Unter dem Motto „Wrapliebe kann auch COVID 19 nicht stoppen“ haben
          sich die Leute von Wrap Stars etwas einfallen lassen. Wrap Stars
          bieten mehrere Möglichkeiten an wie man während der COVI-19-Krise
          nicht auf die köstlichen Wraps verzichten muss. Abholung vor Ort,
          online bestellen, DIY Wrap Kochboxen für Zuhause bestellen oder
          Gutscheine für späteres Vergnügen können unter{' '}
          <a href="www.wrapstars.at">www.wrapstars.at</a> bestellt werden.
        </p>
        <p>
          Um Gastronomiebetriebe Unternehmen zu unterstützen, speziell all jene
          deren Produkte nicht so einfach geliefert werden können, empfiehlt
          sich die Initiative von{' '}
          <a href="www.vorfreude.at">www.vorfreude.at</a> zu unterstützen. Hier
          könnt ihr Gutscheine von diversen Restaurants, Cafés und Cocktailbars
          kaufen, die Betreiber und deren Angestellte unterstützen und euch
          etwas Vorfreude in die Quarantäne holen.
        </p>
        <p>
          Falls du noch weitere Initiativen kennst kannst du uns gerne
          kontaktiere.
        </p>
        <p>Wir freuen uns!</p>
      </Typography>
    </>
  );
};

export default Initiatives;
