import React from 'react';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { TrendingDownRounded, TrendingUpRounded } from '@material-ui/icons';
import numbro from 'numbro';
import { useTranslation } from 'react-i18next';

const StyledTypographyLabel = styled(Typography)`
  text-transform: capitalize;
`;
const StyledBoldTypography = styled(Typography)`
  font-weight: bold;
`;
const StyledChangedTypography = styled(Typography)`
  color: ${(props) => (props.success ? '#68d391' : '#fc8181')};
`;
const StyledUpIcon = styled(TrendingUpRounded)`
  color: ${(props) => (props.success ? '#68d391' : '#fc8181')};
`;
const StyledDownIcon = styled(TrendingDownRounded)`
  color: ${(props) => (props.success ? '#68d391' : '#fc8181')};
`;

const InfoCard = ({
  data: { label, total, relativeChange, absoluteChange },
}) => {
  const { t } = useTranslation();
  const isPositiveInt = (int) => Math.sign(int) === 1 || false;
  const isPositiveDynamic =
    (label === 'totalRecovered' && isPositiveInt(absoluteChange)) ||
    (label === 'currentIntensiveCare' && !isPositiveInt(absoluteChange));
  return (
    <Card>
      <Box>
        <CardContent>
          <Box p={2}>
            <StyledTypographyLabel
              variant="subtitle1"
              color="textSecondary"
              noWrap
            >
              {t(label)}
            </StyledTypographyLabel>
            <StyledBoldTypography gutterBottom variant="h4">
              {numbro(Number(total)).format({ thousandSeparated: true })}{' '}
              {isPositiveInt(absoluteChange) ? (
                <StyledUpIcon success={isPositiveDynamic} />
              ) : (
                <StyledDownIcon success={isPositiveDynamic} />
              )}
            </StyledBoldTypography>
            <Box>
              <StyledChangedTypography success={isPositiveDynamic}>
                {isPositiveInt(absoluteChange) && '+'}
                {numbro(Number(absoluteChange)).format({
                  thousandSeparated: true,
                })}
                {` (${relativeChange})`}
              </StyledChangedTypography>
            </Box>
          </Box>
        </CardContent>
      </Box>
    </Card>
  );
};

export default InfoCard;
