import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

const StyledList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  li {
    font-weight: 300;
  }
`;

const About = () => {
  return (
    <div>
      <Typography variant="h5">Über das Projekt:</Typography>
      <Typography variant="body" align="justify">
        <p>
          Wir, die Betreiber von <b>co.vid19.at</b>, möchten Ihnen mit dieser
          Seite einen zentralen und verständlichen Überblick über die aktuelle
          Situation des Coronavirus SARS-CoV-2 und dessen Entwicklung in
          Österreich verschaffen.
        </p>
        <p>
          Es zirkulieren täglich fast schon unüberschaubare Mengen an
          Informationen zum Thema COVID-19 durchs Netz. Deshalb haben wir es uns
          zur Aufgabe gemacht die verfügbaren und validierten Daten des
          Coronavirus SARS-CoV-19 zu sammeln, zu analysieren und zu
          veröffentlichen. Dies soll jedem einen <b>besseren Überblick</b>{' '}
          ermöglichen um das Ausmaß des Problems und die damit eventuell
          verbundenen persönliches Risiken besser einschätzen zu können.
        </p>
        <p>
          Wir beziehen die von uns analysierten Daten, die Sie in den Grafiken
          finden, von <b>offizieller Quelle</b> aus dem Bundesministerium für
          Arbeit, Soziales, Gesundheit und Konsumentenschutz.
        </p>
        <p>
          Wie wichtig die gesammelten, aktuellen und transparent
          veröffentlichten Daten für das Handeln des Gesundheitssystems sein
          können, zeigen die Erfahrungen jener Länder, die es geschafft haben
          die Ausbreitung des <b>COVID-19 Virus einzudämmen</b> . Gleichzeitig
          schafft es aber auch ein grundlegendes Verständnis der Bevölkerung,
          die Situation besser zu verstehen und die Maßnahmen der Regierung
          besser und effizienter umzusetzen. Je mehr wir wissen, desto größer
          ist auch unser Beitrag diese <b>Pandemie gemeinsam zu bewältigen</b> ,
          indem wir uns und andere Personen schützen und unterstützen.
        </p>
        <p>
          Die Initiative Cov.id19.at wurde von <b>Vienna Digital Lab</b> ins
          Leben gerufen. Diese Seite dient keinem kommerziellen Zweck und die
          Mitarbeit beruht auf freiwilliger Basis.
        </p>
        <p>
          Wir sehen dieses Projekt als{' '}
          <b>Gemeinschaft von freiwillig Mitwirkenden</b>
          an, die bei Einträgen, Prüfung und Programmierung der Daten helfen.
          Falls Sie Interesse haben uns bei diesem Projekt zu unterstützen, dann
          treten Sie gerne mit uns in Kontakt. Über Ihr Feedback sowie über die
          Mitwirkung beim Verfassen von Beiträgen, der Datenauswertung und dem
          Gestalten von Initiativen rund um das Thema COVID-19 würden wir uns
          freuen.
        </p>
        <p>Ihr Cov.id19.at Team:</p>

        <StyledList>
          <li>Dimitri Ivashchuk</li>
          <li>Friederieke Förster-Kinsella</li>
          <li>Kerstin Ista</li>
          <li>Mario Berger</li>
          <li>Martin Schwerdtfeger</li>
        </StyledList>
      </Typography>
    </div>
  );
};

export default About;
