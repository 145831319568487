import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@material-ui/core';
import axios from 'axios';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import styled from 'styled-components';
import { ResponsiveLine } from '@nivo/line';
import { TrendingUpSharp, TrendingDownSharp } from '@material-ui/icons';

const StyledTrend = styled(Typography)`
  color: ${(props) => (props.value > 0 ? 'salmon' : 'mediumseagreen')};
`;

const getSecondGraphData = async () => {
  const { data } = await axios.get('/.netlify/functions/getSecondGraphData');
  return data;
};

const transformGraphData = (data) => {
  const positive = [];
  const negative = [];
  data.forEach((v) => {
    if (Number(v.currentInfectedRel) === 0) {
      positive.push({ x: v.date, y: 0 });
      negative.push({ x: v.date, y: 0 });
    } else {
      if (Number(v.currentInfectedRel) > 0) {
        positive.push({ x: v.date, y: Number(v.currentInfectedRel) });
        negative.push({ x: v.date, y: null });
      } else {
        negative.push({ x: v.date, y: Number(v.currentInfectedRel) });
        positive.push({ x: v.date, y: null });
      }
    }
  });
  return [
    { id: 'positive', data: positive },
    { id: 'negative', data: negative },
  ];
};

const commonProperties = {
  height: 400,
  margin: { top: 20, right: 20, bottom: 60, left: 80 },
  animate: true,
  enableSlices: 'x',
};

const CustomSymbol = ({ size, color, borderWidth, borderColor }) => (
  <g>
    <circle
      fill="#fff"
      r={size / 2}
      strokeWidth={borderWidth}
      stroke={borderColor}
    />
    <circle
      r={size / 5}
      strokeWidth={borderWidth}
      stroke={borderColor}
      fill={color}
      fillOpacity={0.35}
    />
  </g>
);

const FirstGraph = () => {
  const { status, data, error, isFetching } = useQuery(
    'secondGraph',
    getSecondGraphData,
  );

  return status === 'loading' ? (
    '...'
  ) : (
    <Box mt={1}>
      <Typography variant="h5" gutterBottom>
        Wachstumsrate COVID-19 Infektionen
      </Typography>
      <div style={{ height: '400px' }}>
        {status === 'loading' ? (
          'loading'
        ) : (
          <ResponsiveLine
            {...commonProperties}
            data={
              console.log(transformGraphData(data.aggregateValues)) ||
              transformGraphData(data.aggregateValues)
            }
            curve={'monotoneX'}
            pointSymbol={CustomSymbol}
            pointSize={14}
            pointBorderWidth={1}
            pointBorderColor={{
              from: 'color',
              modifiers: [['darker', 0.3]],
            }}
            pointLabelYOffset={-20}
            enableGridX={false}
            colors={['rgb(244, 117, 96)', 'rgb(97, 205, 187)']}
            xScale={{
              type: 'time',
              format: '%d.%m.%Y',
              precision: 'day',
            }}
            axisBottom={{
              format: '%b %d',
              tickValues: 'every 5 days',
              legendOffset: -12,
              tickRotation: 35,
            }}
            xFormat="time:%d.%m.%Y"
            yScale={{
              type: 'linear',
              stacked: false,
              min: -0.2,
              max: 0.4,
            }}
            tooltip={({ point }) => (
              <div
                style={{
                  backgroundColor: 'white',
                  padding: '10px 20px',
                  borderRadius: '7px',
                  border: '1px solid #ccc',
                }}
              >
                <Typography variant="h6">
                  {format(point.data.x, 'MM/dd/yyyy')}
                </Typography>
                <Box display="flex">
                  <StyledTrend value={point.data.y}>{point.data.y}</StyledTrend>
                  {point.data.y > 0 ? (
                    <StyledTrend value={point.data.y}>
                      <TrendingUpSharp />
                    </StyledTrend>
                  ) : (
                    <StyledTrend>
                      <TrendingDownSharp />
                    </StyledTrend>
                  )}
                </Box>
              </div>
            )}
            enableArea={true}
            areaOpacity={0.07}
            enableSlices={false}
            useMesh={true}
            crosshairType="cross"
          />
        )}
      </div>
    </Box>
  );
};

export default FirstGraph;
