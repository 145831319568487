import React from 'react';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import axios from 'axios';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledTable = styled(Table)`
  overflow-x: auto;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  padding: 10px;
  margin: 10px;
`;
const StyledTableCell = styled(TableCell)`
  font-size: 16px;
  font-weight: ${(props) => props.header === 'region' && 'bold'};
`;

const getFirstTableData = async () => {
  const { data } = await axios.get('/.netlify/functions/getFirstTableData');
  return data;
};

const FirstTable = () => {
  const { status, data, error, isFetching } = useQuery(
    'firstTable',
    getFirstTableData,
  );
  const { t } = useTranslation();

  return status === 'loading' ? (
    '...'
  ) : (
    <Box mt={1}>
      <Typography variant="h5" gutterBottom>
        COVID-19 Statistiken pro Bundesland
      </Typography>
      <Box display="flex" justifyContent="center">
        <TableContainer component={Paper}>
          <StyledTable aria-label="customized table">
            <TableHead>
              <TableRow>
                {Object.keys(data.aggregateValues[0]).map((v) => (
                  <TableCell>{t(v)}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.aggregateValues.map((v) => (
                <TableRow>
                  {Object.keys(data.aggregateValues[0]).map((headerName) => (
                    <StyledTableCell header={headerName}>
                      {v[headerName]}
                    </StyledTableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default FirstTable;
