import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@material-ui/core';

import FirstGraph from '../../graphs/firstGraph';
import SecondGraph from '../../graphs/secondGraph';
import ThirdGraph from '../../graphs/thirdGraph';
import FourthGraph from '../../graphs/fourthGraph';

import FirstTable from '../../tables/firstTable';

const Graphs = () => {
  return (
    <>
      <FirstGraph />
      <SecondGraph />
      <ThirdGraph />
      <FourthGraph />
      <FirstTable />
    </>
  );
};

export default Graphs;
