export default {
  translation: {
    totalCasesAut: 'Bestätigte Fälle gesamt',
    totalHospitalized: 'Spitalsfälle',
    totalIntensiveCare: 'Auf Intensivstationen',
    totalRecovered: 'Genesene Personen',
    totalDeaths: 'Todesfälle',
    testsConducted: 'Bisher durchgeführte Tests',
    caseDoubleDays: 'Fallverdopplung in Tagen',
    region: 'Region',
    hospitalizedGR: 'Wachstumsrate Spitalsfälle',
    currentICU: 'Personen zurzeit auf Intensivstation ',
    icuGR: 'Wachstumsrate Intensivstation ',
    official: 'Offizielle Informationen Österreich',
    officialInt: 'Offizielle Informationen International',
    wissenwertes: ' Wissenswertes ',
    twitterLinks: ' Twitter Links',
    newCasesPerDay: 'Neuinfektionen pro Tag',
    newTestsPerDay: 'Neue Tests pro Tag',
    totalCases: 'Bestätigte Fälle gesamt',
    currentHospitalized: 'Spitalsfälle',
    currentIntensiveCare: 'Intensivstation Belegung',
  },
};
