import React from 'react';
import { Typography, Box } from '@material-ui/core';

import ResourcesOverview from '../../components/ResourcesOverview';

const Home = () => {
  return (
    <Box>
      <ResourcesOverview />
    </Box>
  );
};

export default Home;
