import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, Link, Card, Divider } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useQuery } from 'react-query';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const getResources = async () => {
  const { data } = await axios.get('/.netlify/functions/getResourcesData');
  return data;
};

const ResourcesOverview = () => {
  const { status, data, error, isFetching } = useQuery(
    'resources',
    getResources,
  );
  const { t } = useTranslation();

  const official =
    data && data.aggregateValues.filter((v) => v.type === 'officialAt');
  const officialInt =
    data && data.aggregateValues.filter((v) => v.type === 'officialInt');
  const wissenwertes =
    data && data.aggregateValues.filter((v) => v.type === 'wissenwertes');
  const twitterLinks =
    data && data.aggregateValues.filter((v) => v.type === 'twitterLinks');

  const transformedData = [
    { type: 'official', list: official },
    { type: 'officialInt', list: officialInt },
    { type: 'wissenwertes', list: wissenwertes },
    { type: 'twitterLinks', list: twitterLinks },
  ];

  return (
    <>
      <Box mt={2}>
        <Grid container spacing={4}>
          {status === 'loading' ? (
            <>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <Skeleton variant="rect" height={180} />
              </Grid>
            </>
          ) : (
            transformedData.map((v, i) => (
              <Grid key={i} sm={12}>
                <Typography variant="h4" gutterBottom>
                  {t(v.type)}
                </Typography>
                {v.list.map((item) => {
                  return (
                    <Box mb={2} maxWidth={500}>
                      <Card variant="outlined">
                        <Box p={2} mb={3}>
                          <Typography gutterBottom>
                            <b>{item.title}</b>
                          </Typography>

                          {item.body.includes('---') ? (
                            item.body.split('---').map((v, i) => {
                              return (
                                <Box mb={2}>
                                  <Typography>{v}</Typography>
                                  <Typography variant="subtitle2">
                                    <Link>{item.link.split('---')[i]}</Link>
                                  </Typography>
                                </Box>
                              );
                            })
                          ) : (
                            <Typography>{item.body}</Typography>
                          )}
                          <Typography variant="subtitle2">
                            <Link>
                              {item.link.includes('---') ? null : item.link}
                            </Link>
                          </Typography>
                        </Box>
                      </Card>
                    </Box>
                  );
                })}
              </Grid>
            ))
          )}
        </Grid>
      </Box>
    </>
  );
};

export default ResourcesOverview;
