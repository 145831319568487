import React, { useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import numbro from 'numbro';
import { Box, Chip, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledColor = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50px;
  margin-right: 8px;
  background-color: ${(props) => props.color};
  border: 0.5px solid #999;
`;

const StyledSerieLabel = styled.div`
  margin-right: 6px;
`;

const commonProperties = {
  margin: { top: 20, right: 20, bottom: 60, left: 80 },
  animate: true,
};

const LineGraph = ({ data, filterPills, preselectPills }) => {
  const [hidden, setHidden] = useState(preselectPills);
  const { t } = useTranslation();

  const toggle = (x) => {
    const addOrRemove = (arr, item) =>
      arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item];
    setHidden(addOrRemove(hidden, x));
  };

  const filteredData = data.filter((v) => !hidden.includes(v.id));

  return (
    <>
      <div style={{ height: '400px', width: '100%' }}>
        <ResponsiveLine
          {...commonProperties}
          data={filteredData}
          curve="monotoneX"
          colors={{ scheme: 'pastel2' }}
          lineWidth={2}
          enableCrosshair
          enableSlices="x"
          xScale={{
            type: 'time',
            format: '%d.%m.%Y',
            precision: 'day',
          }}
          axisBottom={{
            format: '%b %d',
            tickValues: 'every 5 days',
            legendOffset: -12,
            tickRotation: 35,
          }}
          xFormat="time:%d.%m.%Y"
          yScale={{
            type: 'linear',
          }}
          axisLeft={{
            legendOffset: 15,
            format: (value) =>
              numbro(value).format({ thousandSeparated: true }),
          }}
          pointBorderWidth={1}
          pointBorderColor={{
            from: 'color',
            modifiers: [['darker', 0.3]],
          }}
          useMesh={true}
          sliceTooltip={({ slice }) => (
            <div
              style={{
                backgroundColor: 'white',
                padding: '10px 20px',
                borderRadius: '7px',
                border: '1px solid #ccc',
              }}
            >
              <Typography variant="h6">
                {format(slice.points[0].data.x, 'MM/dd/yyyy')}
              </Typography>
              {slice.points.map((point) => (
                <Box display="flex" flexDirection="row" alignItems="center">
                  <StyledColor color={point.serieColor} />
                  <StyledSerieLabel>{t(point.serieId)}</StyledSerieLabel>
                  <Typography variant="caption">
                    {numbro(point.data.y).format({ thousandSeparated: true })}
                  </Typography>
                </Box>
              ))}
            </div>
          )}
        />
      </div>
      <Box display="flex" justifyContent="center" flexWrap="wrap" mb={5}>
        {filterPills.map((pill) => (
          <Box mb={1}>
            <Chip
              onClick={() => toggle(pill)}
              style={{ marginRight: 8 }}
              label={t(pill)}
              color="primary"
              variant={hidden.includes(pill) ? 'outlined' : 'default'}
            />
          </Box>
        ))}
      </Box>
    </>
  );
};

export default LineGraph;
