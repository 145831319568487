import React from 'react';
import { Typography, Box } from '@material-ui/core';

import AggregateOverview from '../../components/AggregateOverview';
import Graphs from '../../components/Graphs';

const Home = () => {
  return (
    <Box>
      <AggregateOverview />
      <Graphs />
    </Box>
  );
};

export default Home;
