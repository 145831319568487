import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@material-ui/core';
import axios from 'axios';
import { useQuery } from 'react-query';

import LineGraph from '../../components/LineGraph';

const getThirdGraphData = async () => {
  const { data } = await axios.get('/.netlify/functions/getThirdGraphData');
  return data;
};

const FirstGraph = () => {
  const { status, data, error, isFetching } = useQuery(
    'thirdGraph',
    getThirdGraphData,
  );

  const transformGraphData = (arr) => {
    if (arr.length > 0) {
      const getColumnNames = (arr) =>
        Object.keys(arr[0]).filter((v) => v !== 'date');
      const columns = getColumnNames(arr);
      let rows = [];
      columns.forEach((columnName) =>
        rows.push({
          id: columnName,
          data: arr.map((dataPoint) => ({
            x: dataPoint.date,
            y:
              dataPoint[columnName] === ''
                ? null
                : Number(dataPoint[columnName]),
          })),
        }),
      );
      return rows;
    }
  };

  return status === 'loading' ? (
    '...'
  ) : (
    <Box mt={1}>
      <Typography variant="h5" gutterBottom>
        Neue Tests / Neuinfektionen
      </Typography>
      <LineGraph
        data={transformGraphData(data.aggregateValues)}
        preselectPills={[]}
        filterPills={Object.keys(data.aggregateValues[0]).filter(
          (v) => v !== 'date',
        )}
      />
    </Box>
  );
};

export default FirstGraph;
